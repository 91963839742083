import { useRouter } from 'next/router';
import React from 'react';

type ErrorTrackingScriptProps = {
  status: '200' | '404' | '500' | '403';
};

// Note: We can't use asPath from the Next Router because it gives a different value on the server than it does on the client for the error pages, which means we get a 'did not match' error while hydrating. And because we can't reference window in the render, we need to set state from useEffect on mount. Which is fine, because it's an error page.
const useGetErrorData = () => {
  const router = useRouter();
  const [url, setUrl] = React.useState<string>();

  React.useEffect(() => {
    setUrl(window.location.pathname);
  }, []);

  return { url, languageCode: router.locale };
};

export const ErrorTrackingScript = ({ status = '200' }: ErrorTrackingScriptProps) => {
  const { languageCode, url } = useGetErrorData();

  return (
    <script
      id="error-tracking-script"
      dangerouslySetInnerHTML={{
        __html: `
              window.digitalData = {
                page: {
                  pageInfo: {
                    pageName: "${status} pagina",
                    pagePath: "${url}",
                    status: "${status}",
                    issueDate: "not available",
                    version: "not available",
                    languageCode: "${languageCode}",
                    pageFormat: "not available",
                  }
                },
              }
            `,
      }}
    />
  );
};
