import "../../libs/ads-core/src/components/GridItem/GridItem.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/GridItem/GridItem.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62Uy27CMBBF93yFl0VyEISUVzf9FRMbMORBnVCoKv69FFThcdLxOMo6M+cmzj0eZQdtsv2RfQ8Yk7o6ZuJrxTaZurwNroPR4+nH/enWaBmZ8hxVtTD1in0K8xJFj4nx0Bo3cFwVEg6/2sPVc1gYJeCksCfr52RaZqe8aHuRjb1xamw03uVwn3/PldSCValRqmCikOwl10V01rLerdh8tjhehncWOBDG/igTCJ1w53BuIb/j2Am271yfmcbJnMH9GXfO2M1sfHr7hpVYOYlruL/mzr+yE5v/sn3ciquduC3c33LnR9tx/xeifc2KPTmxGdzPuNOXltjG0bYvXb1Nm4zjxFO1GCbF3NMiQvVoDKSKc8ibc0/PvNWkEZCqppCXck8V8erS1pEq7yBvxz0VpVWbhkGqnkNezj0lplSfBiGokCRjjwpTmDzlga0mqNGNiaiygPwFD+y9V51uREQlhy95oBq4Wt1wiGoa8jUk+JWhqdcNi6hYQH4BCX6pKGp2gxJUXcY+VRP4JgnMCreMoG4/GYjKS5i3hIhwD71q95OAqK5gnoKIcFVx9fvBI1fBHubtISJcYdrV0E8MclWUMK+EiHDJKVdHPyG3q+QHesHRP3AOAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var gridArea = 'lkirljs';
export var gridAreaVars = {value:'gridArea',vars:{initial:'var(--lkirlja)',sm:'var(--lkirljb)',md:'var(--lkirljc)',lg:'var(--lkirljd)',xl:'var(--lkirlje)'},extractedVars:{initial:'--lkirlja',sm:'--lkirljb',md:'--lkirljc',lg:'--lkirljd',xl:'--lkirlje'}};
export var gridColumnEnd = 'lkirlju';
export var gridColumnEndVars = {value:'gridColumnEnd',vars:{initial:'var(--lkirljk)',sm:'var(--lkirljl)',md:'var(--lkirljm)',lg:'var(--lkirljn)',xl:'var(--lkirljo)'},extractedVars:{initial:'--lkirljk',sm:'--lkirljl',md:'--lkirljm',lg:'--lkirljn',xl:'--lkirljo'}};
export var gridColumnStart = 'lkirljt';
export var gridColumnStartVars = {value:'gridColumnStart',vars:{initial:'var(--lkirljf)',sm:'var(--lkirljg)',md:'var(--lkirljh)',lg:'var(--lkirlji)',xl:'var(--lkirljj)'},extractedVars:{initial:'--lkirljf',sm:'--lkirljg',md:'--lkirljh',lg:'--lkirlji',xl:'--lkirljj'}};
export var gridRowEnd = 'lkirljr';
export var gridRowEndVars = {value:'gridRowEnd',vars:{initial:'var(--lkirlj5)',sm:'var(--lkirlj6)',md:'var(--lkirlj7)',lg:'var(--lkirlj8)',xl:'var(--lkirlj9)'},extractedVars:{initial:'--lkirlj5',sm:'--lkirlj6',md:'--lkirlj7',lg:'--lkirlj8',xl:'--lkirlj9'}};
export var gridRowStart = 'lkirljq';
export var gridRowStartVars = {value:'gridRowStart',vars:{initial:'var(--lkirlj0)',sm:'var(--lkirlj1)',md:'var(--lkirlj2)',lg:'var(--lkirlj3)',xl:'var(--lkirlj4)'},extractedVars:{initial:'--lkirlj0',sm:'--lkirlj1',md:'--lkirlj2',lg:'--lkirlj3',xl:'--lkirlj4'}};
export var root = 'lkirljp';